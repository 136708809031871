//
// menu.scss
//

// Wrapper
.layout-wrapper {
    display: flex;
    flex: 1 1 auto;
    height: 100%;
    width: 100%;

    .main-menu {
        border: none;

        .app-menu {
            .sub-menu {
                li {
                    ::after {
                        content: none;
                    }
                }
            }
        }
    }
}

// Content Page
.page-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    min-block-size: 100vh;
    transition: $transition-base;

    .navbar-custom {
        background-color: #415763;
        color: white;
        border: none !important;

        .topbar {
            .topbar-menu {
                a.nav-link {
                    color: white !important;
                }

                .dropdown {

                    li::before,
                    a,
                    label {
                        color: white;
                    }

                    .dropdown-menu {

                        a,
                        li {
                            color: black !important;
                        }
                    }
                }

                .button-toggle-menu {
                    color: white !important;
                }
            }
        }

    }
}

// Logo
.logo-box {
    top: 0;
    position: sticky;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    height: $topbar-height;
    transition: $transition-base;
    padding: 0 $menu-item-padding-x;

    .logo-sm {
        display: none;
    }

    .logo-light {
        //display: none;
    }

    .logo-dark {
        display: block;
    }
}

html[data-menu-color="brand"],
html[data-menu-color="dark"],
html[data-bs-theme="dark"] {
    .main-menu {
        .logo-box {
            .logo-light {
                display: block;
            }

            .logo-dark {
                display: none;
            }
        }
    }
}

html[data-bs-theme="dark"] {
    #theme-mode {
        .bx-moon:before {
            content: "\ec34";
        }
    }

    .main-menu {
        border-right-style: dashed;
    }

    .navbar-custom {
        border-bottom-style: dashed;
    }

    .auth-logo {
        .logo-light {
            display: block;
        }

        .logo-dark {
            display: none;
        }
    }
}

.auth-logo {
    .logo-light {
        display: none;
    }

    .logo-dark {
        display: block;
    }
}