body.page-login {
    &.bg-primary {        
        background-size: cover;  

        .logo-light {
            background-color: white;
            border-radius: 5px;

            img {
                max-width: 100%;
                color: $ftf-color-gris;
            }
        }
    }

    #divMensaje{
        display: none;
        text-align: center; 
        font-weight: 800;
        color:#872a2e;
    }

    .card{
        backdrop-filter: blur(15px) !important;
        background-color: #f1f5f71a  !important;
        border-color: #ffffff !important;
        border-radius: 1.18rem;
        box-shadow: 11px 11px 30px 0px rgba(0,0,0,0.5);
        -webkit-box-shadow: 11px 11px 30px 0px rgba(0,0,0,0.5);
        -moz-box-shadow: 11px 11px 30px 0px rgba(0,0,0,0.5);  
       
        .needs-validation{ 
            .form-label{
                color: white !important;
                text-shadow: 0 0 white;  

                color: $ftf-color-celeste   !important;
                text-shadow: 0 0 $ftf-color-celeste !important;
                font-weight: 700 !important;

            } 
            .form-control{       
                background-color: transparent !important;         
                color: $ftf-color-celeste !important; 
                border-radius: 0px !important;
                border-color: transparent  !important;
                border-bottom-color: $ftf-color-celeste  !important;
                //padding-left: 0px !important;  
                &::placeholder {
                    color:  $ftf-color-celeste !important; 
                    opacity: 1; /* Firefox */
                    font-weight: 400 !important; 
                }                  
            } 
            .form-group{
                .btn{
                    &.btn-primary { 
                        background-color: #009aff8a   !important;
                        border-color:  transparent !important;  
                        border-radius: 20px !important;  
                        font-weight: 700 !important;  
                        &:hover{
                            background-color:  $ftf-color-celeste !important;
                            border-color:  transparent !important;
                        } 
                    }  
                }  
            }   
        }  
    }

 
    

}