/*loading*/
.loaging.vw-100.vh-100 {
    background-color: rgba(0, 0, 0, 0.65);
    z-index: 9999;
    top: 0;
    left: 0;
}

/*navbar*/
.navbar-custom {
    .topbar {
        .nav-link {
            :hover {
                color: $ftf-color-celeste !important;
            }
        }
    }
}

/*sidebar*/
.main-menu {
    background: $ftf-color-gris;


    .logo-box {
        background-color: transparent !important;

        a {
            border-radius: 4px;
            //margin-top: 25px; 
            padding: 5px;
        }

        .logo-sm {
            height: 38px;
        }
    }

    .app-menu {
        .menu-title {
            color: $ftf-color-gris;
        }

        .menu-item {
            .badge {
                &.bg-primary {
                    background-color: $ftf-color-celeste !important;
                }
            }
        }
    }
}

/*sidebar not full*/
html.sidebar-enable:not([data-sidebar-size=full]) .main-menu .app-menu .menu-item>.menu-link .menu-text {
    background-color: $ftf-color-celeste !important;

    &.menu-child {
        background-color: transparent !important;
    }
}




.page-content {

    /*Boton primary*/
    .btn {
        &.btn-primary {
            background-color: $ftf-color-celeste !important;
            border-color: $ftf-color-celeste !important;
        }

        &.btn-secondary {
            background-color: $ftf-color-gris-claro !important;
            border-color: $ftf-color-gris-claro !important;
        }
    }


    /*Datatable - Paginación grilla*/
    .pagination-rounded .active>.page-link {
        background-color: $ftf-color-celeste !important;
    }

    /*Datatable - Burbuja + - expande contrae primera fila*/
    table.dataTable.dtr-inline.collapsed>tbody>tr.parent>td:first-child:before,
    table.dataTable.dtr-inline.collapsed>tbody>tr.parent>th:first-child:before {
        background-color: #fa5944;
    }

    table.dataTable.dtr-inline.collapsed>tbody>tr th.dtr-control:before,
    table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control:before {
        background-color: $ftf-color-celeste ;
        -webkit-box-shadow: 0 0 2px 0 $ftf-color-celeste ;
        box-shadow: 0 0 2px 0 $ftf-color-celeste ;
    }

    /*Modales*/
    .modal-dialog {
        margin-top: -20px;

        .modal-content {
            padding: 15px 20px;

            .modal-header {
                border-bottom: none;
            }

            .modal-footer {
                border-top: none;
            }

            .modal-body {
                .switchery-on {
                    background-color: $ftf-color-celeste !important;
                    border-color: $ftf-color-celeste !important;
                    box-shadow: $ftf-color-celeste 0px 0px 0px 16px inset !important;
                    transition: border 0.4s ease 0s, box-shadow 0.4s ease 0s, background-color 1.2s ease 0s !important;
                }

                .switchery-off {
                    background-color: rgb(255, 255, 255) !important;
                    border-color: rgb(223, 223, 223) !important;
                    box-shadow: rgb(223, 223, 223) 0px 0px 0px 0px inset !important;
                    transition: border 0.4s ease 0s, box-shadow 0.4s ease 0s !important;
                }
            }
        }
    }

    #lblValidacion,
    #lblValidacionPortafolio,
    #lblValidacionEliminar {
        color: #e14d45;
    }

    .icon-radius {
        background-color: coral !important;
        border-radius: 50% !important;
    }

    /*Tooltips
    .tooltip-inner {
        max-width: var(--bs-tooltip-max-width);
        padding: 10px !important;
        color: var(--bs-tooltip-color);
        text-align: center;
        background-color: var( $ftf-color-celeste ) !important;
        border-radius: 20px !important;
    }*/

}

.page-content {
    &.page-expertos-listado {

        .text-titulo {
            font-size: 14px;
            margin-top: -33px;
            margin-left: 80px;
            position: absolute;

            @media (min-width: 380) {
                margin-top: -45px;
                margin-left: 80px;
                position: absolute;
            }
        }


        .botonera {
            .btn-light {
                color: white !important;
                background-color: $ftf-color-gris;

                :hover {
                    border-radius: 50% !important;
                    border-color: $ftf-color-gris-claro !important;
                    background-color: $ftf-color-gris-claro !important;
                }
            }

            .avatar-md {
                height: 3.1rem !important;
                width: 3.1rem !important;
            }
        }

        //busqueda
        #datatableId-experto_filter {
            display: none;
        }


        /*dropdown menú en item de grilla*/
        .selectize-control.multi .selectize-input>div {
            background-color: $ftf-color-gris-claro !important;
            color: $white;
            margin-top: -20px !important;
        }


        table.dataTable tbody tr td {

            /*Columna Filtrada*/
            &.especialidad-filtrada {
                background-color: $ftf-color-gris !important;
                color: $white;
            }

            .dropdown-item {
                font-size: 15px;
                cursor: pointer !important;
            }

            //Columna acciones
            .btn-group {

                /*
                .btn.btn-primary {
                    background-color: $ftf-color-celeste !important;
                    border-color: $ftf-color-gris-claro !important;
                }*/

                .btn-outline-primary {
                    color: $ftf-color-celeste !important;
                    border-color: $ftf-color-celeste !important;
                    border-radius: 0;

                    &.acciones {
                        background-color: transparent !important;
                        border-color: $ftf-color-gris-claro !important;
                        //border-left: transparent;
                        color: $ftf-color-gris !important;
                    }
                }

                .btn-outline-primary:hover {
                    background-color: $ftf-color-celeste !important;
                    border-color: $ftf-color-celeste !important;
                    color: $white !important;

                    &.acciones:hover {
                        background-color: transparent !important;
                        border-color: $ftf-color-gris-claro !important;
                        //border-left: transparent;
                        color: $ftf-color-gris !important;
                    }
                }

                //btn seleccionado
                .btn.show {
                    background-color: $ftf-color-celeste !important;
                    color: $white !important;
                }

            }

            .badge {
                &.rounded-pill {
                    &.rounded-pill {
                        :hover {
                            text-decoration: underline;
                        }

                        .portafolio {
                            color: white;
                            font-size: 13px;
                            padding-bottom: 30px;
                        }

                        i {
                            font-size: 20px;
                        }
                    }
                }
            }

            //fin badge
            &.dtr-control {
                &::before {
                    left: 16px;
                }
            }

        }

        //fin table.dataTable tbody tr td


        table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control:before,
        table.dataTable.dtr-inline.collapsed>tbody>tr>th.dtr-control:before {
            top: 38%;
        }



        .sorting_disabled {

            &::before,
            &::after {
                display: none;
            }
        }

        /*Boton icon editar, download de datatable*/
        .btnEditarItem,
        .btnDownloadItem,
        .btnEliminarItem,
        .btnAudioItem {
            font-size: 14px !important;
            border-radius: 50% !important;
            padding: 6px 10px !important;
            margin: 0px !important;
            border: 0px !important;
        }
    }

    .dataTables_scrollBody {
        min-height: 300px !important;
    }


}

.page-content {
    &.page-expertos-registro {

        .dropzone {
            min-height: 55px;
            padding: 0px;
        }

        .dropzone .dz-message {
            margin: 0.75rem 0;
        }

        #cboTipoMoneda {
            font-weight: bold;
        }

        .dropdown-item {
            cursor: pointer !important;
        }

        .accordion-button:not(.collapsed) {
            color: white;
            background-color: #a9b1b7;
        }

        .portafolio-remove {
            i {
                font-size: 20px;

                &:hover {
                    color: #009aff;
                    cursor: pointer;
                }
            }
        }

        .box-file {
            h3 {
                font-size: 1vw;
            }
        }

    }
}

.page-content {

    &.page-skill-grupo,
    &.page-skill-elemento,
    &.page-expertos-listado,
    &.page-usuario {
        //overflow: auto;

        .botonera {

            /*Boton + Nuevo*/
            .btn {
                &.btn-primary {
                    font-size: 24px !important;
                    border-radius: 50% !important;
                    padding: 5px 16px !important;
                    margin: 0px !important;

                    a {
                        color: $white;
                    }
                }
            }
        }

        .badge {
            cursor: pointer;
        }

        /*Boton icon editar de datatable*/
        .btnEditar,
        .btnEliminar {
            font-size: 14px !important;
            border-radius: 50% !important;
            padding: 6px 10px !important;
            margin: 0px !important;
            border: 0px !important;
        }

        /*Menu de datatable*/
        .dropdown-menu {
            .dropdown-item {
                cursor: pointer;
            }
        }

    }





}