/*
Template Name: Dashtrap - Responsive Bootstrap 5 Admin Dashboard
Version: 1.0
Author: Myra Studio
Email: myrathemes@gmail.com
File: Main Css File
*/

// Font Family
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;500;600;700;800&display=swap');

// Core files
@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "config/variables"; // 1. Default
@import "config/variables-dark"; // 2. Dark Mode
@import "config/variables-custom"; // 3. Custom Variable
@import "config/theme-mode"; // 4. Mode
@import "./node_modules/bootstrap/scss/bootstrap";

// Structure
@import "structure/general";
@import "structure/topbar";
@import "structure/vertical";
@import "structure/horizontal";
@import "structure/footer";

// Components
@import "components/accordions";
@import "components/alert";
@import "components/avatar";
@import "components/breadcrumb";
@import "components/buttons";
@import "components/card";
@import "components/custom-forms";
@import "components/dropdown";
@import "components/forms";
@import "components/badge";
@import "components/modal";
@import "components/nav";
@import "components/pagination";
@import "components/popover";
@import "components/print";
@import "components/progress";
@import "components/reboot";
@import "components/ribbons";
@import "components/switch";
@import "components/tables";
@import "components/type";
@import "components/tooltip";
@import "components/utilities";

// Pages
@import "pages/components-demo";
@import "pages/login";
@import "pages/estilos";

// Plugins
@import "plugins/apexcharts";
@import "plugins/morris";
@import "plugins/chartjs";
@import "plugins/ion-rangeslider";
@import "plugins/calendar";
@import "plugins/colorpicker";
@import "plugins/datatable";
@import "plugins/daterange";
@import "plugins/select2";
@import "plugins/simplebar";
@import "plugins/sweetalert";
@import "plugins/selectize";
@import "plugins/multiple-select";
@import "plugins/autocomplete";
@import "plugins/bootstrap-touchspin";
@import "plugins/flatpickr";
@import "plugins/quilljs-editor";
@import "plugins/dropzone";
@import "plugins/google-maps";
@import "plugins/vector-maps";
@import "plugins/datepicker";
@import "plugins/waves";